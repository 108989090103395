import React, { useState } from "react";
import { ProgressBar, FigureCaption } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BootstrapTable from 'fad-react-bootstrap-table-next';
import exchangelogo from "../../../components/logos/exchangelogo.svg";

const ExchangesTable = (props) => {
    const exchanges = props.data
    const [tableKey, setTableKey] = useState(0);

    const handleFeeChange = (name, value) => {
        setTaxas(prev => ({
            ...prev,
            [name]: value
        }));
        setTableKey(tableKey + 1); // Update the table key
    };

    const [taxas, setTaxas] = useState(
        props.fees.reduce((acc, fee) => {
            acc[fee.nome] = fee.soma_taxas; // Initialize with existing taxas
            return acc;
        }, {})
    );

    const columns = [
        {
            dataField: 'Name',
            text: 'Corretora',
            classes: "freeze text-truncate",
            headerClasses: "freeze",
            formatter: (cell, row, rowIndex, extraData) => {
                return (
                    <>
                        <svg className="tablelogo" role="img" aria-labelledby="title">
                            <title id="title">{row.Name}</title>
                            <use href={exchangelogo + "#" + (row.Name.toLowerCase())}></use>
                        </svg>
                        <Link to={'/corretoras/' + row.Name} className="stretched-link">{row.Name}</Link>
                    </>
                )
            }
        }, {
            dataField: 'QuoteVolume',
            text: 'Volume',
            classes: "py-3",
            formatter: (cell, row, rowIndex, extraData) => {
                return <>{parseFloat(row.QuoteVolume).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </>
            }
        }, {
            dataField: 'VolumePercent',
            text: 'MarketShare',
            formatter: (cell, row, rowIndex, extraData) => {
                return (<>
                    <ProgressBar max={Math.max.apply(Math, exchanges.map(function (e) { return e.VolumePercent * 100; }))} now={row.VolumePercent * 100} style={{ minWidth: "2em" }} variant="info" label={`${row.VolumePercent.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 1 })}`} />
                </>)
            }
        }, {
            dataField: 'Taxas',
            text: 'Taxas(%)*',
            formatter: (cell, row, rowIndex, extraData) => {
                return <input
                    className="form-control-sm form-control"
                    type="number"
                    step="0.01"
                    placeholder={parseFloat(taxas[row.Name] || 0).toFixed(2)}
                    onBlur={(e) => handleFeeChange(row.Name, parseFloat(e.target.value) || 0)}
                />
            }
        }, {
            dataField: 'Lucro',
            text: 'Lucro',
            formatter: (cell, row, rowIndex, extraData) => {
                return <> {(parseFloat(taxas[row.Name]) / 100 * row.QuoteVolume).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</>
            }
        }
    ]

    const defaultSorted = [{
        dataField: 'VolumePercent',
        order: 'desc'
    }];

    return (
        <>
            <BootstrapTable
                bordered={false}
                hover
                bootstrap4
                striped
                condensed
                keyField="Name"
                data={exchanges}
                columns={columns}
                defaultSorted={defaultSorted}
                headerClasses="header-class"
                key={tableKey}
            />
            <FigureCaption>
                *   As taxas e lucros são calculados pela soma das taxas <em className="fw-semibold text-primary">Taker</em> e <em className="fw-semibold text-primary">Maker</em>.
            </FigureCaption>
        </>
    )
}
export default ExchangesTable