import React, { useState, useEffect } from "react";
import { Nav, Row, Col, Container, Spinner, Tab, Badge, Breadcrumb, Table, Button } from 'react-bootstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import SymbolMarketTable from './components/SymbolMarketTable';
import ComboChart from "./charts/ComboChart";
import Converter from "./components/Converter";
import coinlogo from "./components/logos/coinlogo.svg";
import NotFound from "./components/NotFound";
// import NewsFeed from "./components/NewsFeed";
import { ReactComponent as Caret } from './components/caret-up-fill.svg';
import SymbolHistoricalData from "./components/SymbolHistorical";
import { doFetchJson } from './utils/api';
import { Helmet } from "react-helmet";
import AdReports from "./utils/AdReports";

function SymbolMarket() {

  const [isLoaded, setIsLoaded] = useState(false)
  const [exchanges, setExchanges] = useState([])
  const [coins, setCoins] = useState([])
  const [btc, setBtc] = useState([])
  const [usdt, setUsdt] = useState([])
  const [frequency, setFrequency] = useState(300)
  const [interval, setIntervalsate] = useState(1440)
  const [active, setActive] = useState('1D')

  const symbol = useParams().Symbol.toUpperCase()
  const location = useLocation()

  const getAPI = React.useCallback(async () => {
    setIsLoaded(false); // Optionally set loading state before fetching
  
    try {
      const exchangeapi = `${process.env.REACT_APP_API_URL}/v1/ticker/last/exchanges/${symbol}`;
      const lastapi = `${process.env.REACT_APP_API_URL}/v1/ticker/last?symbols=BTCBRL,USDTBRL${symbol !== 'BTCBRL' ? ',' + symbol : ''}`;
  
      // Fetch the exchange data
      const res1 = await fetch(exchangeapi);
      const data1 = await doFetchJson(res1, { notFound: "Não foram encontrados dados mensais para os parâmetros informados!" });
      setExchanges(data1.sort((a, b) => parseFloat(b.Volume) - parseFloat(a.Volume)));
  
      // Fetch the last data
      const res2 = await fetch(lastapi);
      const data2 = await doFetchJson(res2, { notFound: "Não foram encontrados dados mensais para os parâmetros informados!" });
      setCoins(data2.filter(entry => entry.Symbol === symbol));
      setBtc(data2.filter(entry => entry.Symbol === 'BTCBRL'));
      setUsdt(data2.filter(entry => entry.Symbol === 'USDTBRL'));
  
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.warn(error);
    }
  
    Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
  }, [symbol]);

  useEffect(() => {
    let timer = setInterval(() => (getAPI()), 100000);
    getAPI();
    if (location.pathname.substring(1).toUpperCase !== symbol) {
      setIsLoaded(false)
      getAPI();
    }
    return () => { clearInterval(timer); }
  }, [getAPI, location, symbol]);

  const handleClick = (f, i) => (e) => {
    const clicked = e.target.id;
    setFrequency(f);
    setIntervalsate(i);
    if (active === clicked) {
      setActive('');
    } else {
      setActive(clicked)
    }
  }

  if (!isLoaded) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else if (exchanges[0] === undefined) {
    return <NotFound />
  } else {
    // const symbolslug = JSON.parse(JSON.stringify(exchanges[0].SymbolName));
    const days_into_ytd = Math.floor((Date.now() - Date.parse(new Date().getFullYear(), 0, 0)) / 86400000) * 1440 // to get minutes into year, divide by 60000 instead of 86400000 (and remove *1440)
    let btcvar = ((coins[0].Last) / btc[0].Last);
    let btcvar24 = ((coins[0]['24h']) / btc[0]['24h']);
    let usdtvar = ((coins[0].Last) / usdt[0].Last);
    let usdtvar24 = ((coins[0]['24h']) / usdt[0]['24h']);

    const ExchangeRateSpecification = {
      "@context": "https://schema.org",
      "@type": "ExchangeRateSpecification",
      "url": "https://mercadocripto.livecoins.com.br/" + exchanges[0].Symbol,
      "name": exchanges[0].SymbolName,
      "currency": exchanges[0].Symbol.slice(0, -3),
      "currentExchangeRate": {
        "@type": "UnitPriceSpecification",
        "price": coins[0].Last,
        "priceCurrency": "BRL"
      }
    };

    const BreadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Criptomoedas",
          "item": "https://mercadocripto.livecoins.com.br/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": exchanges[0].SymbolName,
          "item": "https://mercadocripto.livecoins.com.br/" + exchanges[0].Symbol,
        }
      ]
    };

    const FAQPage = {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Onde comprar " + exchanges[0].SymbolName + "?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "A criptomoeda" + exchanges[0].Symbol.slice(0, -3) + " pode ser negociada em corretoras centralizadas. No Brasil, para comprar diretamente com BRL (reais), a corretora de maior volume é a " + exchanges[0].Exchange + ", onde o par " + exchanges[0].Symbol + " teve volume de " + exchanges[0].QuoteVolume + " nas últimas 24 horas."
          }
        },
        {
          "@type": "Question",
          "name": "Qual é o volume diário (últimas 24 horas) de negociação do " + exchanges[0].SymbolName + "(" + exchanges[0].Symbol + ") no Brasil em reais (BRL)?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "O volume de negociação do " + exchanges[0].Symbol + " nas últimas 24 horas foi " + (exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })) + ", em um total de " + exchanges.length + " corretoras em atividade no Brasil."
          }
        }
      ]
    };

    return (
      <>
        <Helmet>
          <title>{exchanges[0].Symbol + " " + ((coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 5 }))) + " · " + exchanges[0].SymbolName + " · Preço em real brasileiro, volume e gráficos · MercadoCripto"}</title>
          <meta name="description" content={"O preço do " + exchanges[0].SymbolName + ", o volume negociado do " + exchanges[0].Symbol + " e o ágio em todas as corretoras do mercado brasileiro de criptomoedas. Acesso a gráficos do " + exchanges[0].Symbol.slice(0, -3) + " e dados históricos."} />
          <script type="application/ld+json">{JSON.stringify(ExchangeRateSpecification)}</script>
          <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
          <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
        </Helmet>
        <div className="bg-light">
          <Container>
            <Breadcrumb className="pt-2">
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >
                Criptomoedas
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{exchanges[0].SymbolName}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="d-none d-lg-block pb-1" >
              <AdReports/>
            </div>
            <Row className="d-flex mb-2">
              <Col xs="3" md="auto" className="p-1 pl-3 py-0 symbolmarket-responsive">
                <svg className="coinlogo mb-1" role="img" aria-labelledby="title">
                  <title>{exchanges[0].SymbolName}</title>
                  <use href={coinlogo + "#" + (exchanges[0].Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
                </svg>
              </Col>
              <Col>
                <h1 className="h2 text-nowrap ">{exchanges[0].SymbolName} <span className="text-muted h5"> {exchanges[0].Symbol.slice(0, -3)}</span></h1>
                <h2 className="h3 strong toupdate">{
                  (coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    : (0.1 <= coins[0].Last && coins[0].Last > 0.0001) ? ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 8 }))
                      : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 12 }))}
                </h2>
                {(exchanges[0].SymbolName === "Tether" || exchanges[0].SymbolName === "Binance USD" || exchanges[0].SymbolName === "USD Coin") ?
                  "" :
                  <h3 className="h6 text-muted strong ">{usdtvar.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} USDT
                    {(usdtvar - usdtvar24) >= 0
                      ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                      : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                    }
                  </h3>
                }
                {exchanges[0].SymbolName !== "Bitcoin" ?
                  <h3 className="h6 text-muted strong ">{btcvar.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 8, minimumFractionDigits: 5 })} BTC
                    {(btcvar - btcvar24) >= 0
                      ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                      : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                    }
                  </h3>
                  : ""}
              </Col>
              <Col className="ml-4 pt-3">
                <Table borderless responsive className="openmarket-table">
                  <tbody>
                    <tr>
                      <td><span className="h6">Abertura</span></td>
                      <td><span className="h6">{coins[0]['24h'].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></td>
                    </tr>
                    <tr>
                      <td><span className="h6">24h %</span></td>
                      <td>{(coins[0].Last - coins[0]['24h']) >= 0
                        ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                        : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                      </td>
                    </tr>
                    <tr>
                      <td><span className="h6">Var 24h</span></td>
                      <td>{(coins[0].Last - coins[0]['24h']) >= 0
                        ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                        : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col className="ml-4 pt-3">
                <Table borderless responsive className="openmarket-table">
                  <tbody>
                    <tr><td><span className="h6">Volume 24h</span></td></tr>
                    <tr><td><span className="h6 strong toupdate"> {exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br /></span></td></tr>
                    <tr><td><span className="h6 text-muted toupdate">{exchanges.reduce((a, e) => a + e.Volume, 0).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0 })} {exchanges[0].Symbol.slice(0, -3)} </span></td></tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <div className="pt-3">
              <Converter symbol={coins[0]} />
            </div>
          </Container>
        </div>

        <Tab.Container justify defaultActiveKey="Corretoras" id="tab-markets" >
          <div className="bg-light border-bottom  pb-0" id="borderfull">
            <Container className="bg-light d-block bgfull px-0">
              <Nav variant="tabs pl-3 pt-2">
                <Nav.Item>
                  <Nav.Link eventKey="Corretoras">Corretoras <Badge pill bg="gray" className="counter" title="Corretoras disponíveis">{exchanges.length}</Badge></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Grafico">Gráfico</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Historicos"><span className="d-block d-sm-none">Histórico</span><span className="d-none d-sm-block">Dados Históricos</span></Nav.Link>
                </Nav.Item>
              </Nav>
            </Container></div>
          <Container>
            <Tab.Content className="mt-4">
              <Tab.Pane eventKey="Corretoras">
                <Container className="px-0 overflow-auto">
                  <SymbolMarketTable data={exchanges} price={coins[0]} />
                </Container>
              </Tab.Pane>

              <Tab.Pane eventKey="Grafico">
                <Row>
                  <Col>
                    <h4 className="h5" style={{ fontWeight: "600" }}>{exchanges[0].SymbolName} e Real brasileiro <span className="h6 ml-1 text-muted"> {exchanges[0].Symbol}</span></h4>
                  </Col>
                  <Col md="auto">
                    <div className="text-right p-1 bg-light rounded border" id="chart-control">
                      <Button className={`mr-1 ${active === "1D" ? 'active' : ''}`} variant="light" id="1D" size="sm" onClick={handleClick(300, 1440)}>
                        1D
                      </Button>
                      <Button className={`mr-1 ${active === "7D" ? 'active' : ''}`} variant="light" id="7D" size="sm" onClick={handleClick(600, 10080)}>
                        7D
                      </Button>
                      <Button className={`mr-1 ${active === "1M" ? 'active' : ''}`} variant="light" id="1M" size="sm" onClick={handleClick(3600, 43200)}>
                        1M
                      </Button>
                      <Button className={`mr-1 ${active === "3M" ? 'active' : ''}`} variant="light" id="3M" size="sm" onClick={handleClick(7200, 129600)}>
                        3M
                      </Button>
                      <Button className={`mr-1 ${active === "YTD" ? 'active' : ''}`} variant="light" id="YTD" size="sm" onClick={handleClick(28800, days_into_ytd)}>
                        YTD
                      </Button>
                      <Button className={`mr-1 ${active === "1A" ? 'active' : ''}`} variant="light" id="1A" size="sm" onClick={handleClick(86400, 525600)}>
                        1A
                      </Button>
                      <Button className={`mr-1 ${active === "MAX" ? 'active' : ''}`} variant="light" id="MAX" size="sm" onClick={handleClick(259200, 9000000)}>
                        MAX
                      </Button>
                    </div>
                  </Col></Row>
                <ComboChart symbol={symbol} frequency={frequency} interval={interval} last={coins[0].Last} />
              </Tab.Pane>

              <Tab.Pane eventKey="Historicos">
                <Container className="px-0 overflow-hidden">
                  <SymbolHistoricalData symbolname={exchanges[0].SymbolName} symbol={symbol} />
                </Container>
              </Tab.Pane>
            </Tab.Content>
          </Container>
        </Tab.Container>
        {/* <Container className="pt-4 ">
          <NewsFeed slug={symbolslug} />
        </Container> */}
      </>
    );
  }
}

export default SymbolMarket;